import React, { InputHTMLAttributes, useMemo } from 'react';
import styles from './Input.module.scss';
import { AuthInputProps, BaseInputProps } from './Input.types';
import Image from 'next/image';
import { type } from '@testing-library/user-event/utility/type';

export const AuthInput: React.FC<AuthInputProps> = (props) => {
  const { id = '', label = '', labelPosition = 'left', icon } = props;

  return (
    <div className={styles.authInput}>
      {typeof icon === 'string' ? (
        <Image
          src={icon}
          width={24}
          height={24}
          alt="icon"
          className={styles.icon}
          draggable="false"
        />
      ) : (
        icon && <span className={styles.icon}>{icon}</span>
      )}
      <Input id={id} label={label} {...(props as InputHTMLAttributes<HTMLInputElement>)} />
    </div>
  );
};

export const Input: React.FC<BaseInputProps> = React.memo((props) => {
  const { children, id, label, type, labelPosition, ref, ...rest } = props;

  if (label && !id) {
    throw new Error('id prop is required when label is provided.');
  }

  return (
    <div className={`${styles.input} ${labelPosition === 'up' ? styles.up : styles.side}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <input ref={ref} type={type || 'text'} {...(rest as InputHTMLAttributes<HTMLInputElement>)} />
      {children}
    </div>
  );
});
