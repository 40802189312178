'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { AuthCard, BackHomePage } from '@/components/Card';
import { AuthInput } from '@/components/Input';
import { AuthButton, SocialMediaButton } from '@/components/Button/Button';
import styles from './Login.module.scss';
import { useRouter } from 'next/navigation';

function Login() {
  const router = useRouter();

  const SetAuthToken = () => {
    // TODO: Düzenlenecek!
    document.cookie = `test=${encodeURIComponent('12345')}; SameSite=Strict; Path=/;`;
    router.push('/main');
  };

  return (
    <>
      <div className={styles.effect}></div>
      <BackHomePage />

      <div className="container">
        <AuthCard>
          <div className={styles.headerImages}>
            <Image src={'/images/dotted-effect.svg'} width={50} height={50} alt={'dottedEffect'} />
            <Image
              src={'/images/book-icon-login-register.png'}
              width={50}
              height={50}
              alt={'logoIcon'}
            />
            <Image src={'/images/dotted-effect.svg'} width={50} height={50} alt={'dottedEffect'} />
          </div>
          <span className={styles.welcomeText}>HOŞGELDİNİZ</span>
          <span className={styles.noAccountText}>
            Henüz hesabınız yoksa
            <Link href="/register" replace>
              Kayıt Olun
            </Link>
          </span>
          <form className={styles.inputGroup}>
            <AuthInput id="email" type="email" placeholder="Email" icon={'/images/mail-icon.svg'} />
            <AuthInput
              id="password"
              type="password"
              placeholder="Şifre"
              icon={'/images/password-icon.svg'}
            />
            <span className={styles.forgotPassword}>
              Şifrenizi unuttuysanız
              <Link href="/forgot-password">tıklayınız</Link>
            </span>
            <AuthButton onClick={() => SetAuthToken()}>Giriş Yap</AuthButton>
          </form>
          <div className={styles.separatorContainer}>
            <span className={styles.separator} />
            <span className={styles.text}>VEYA</span>
            <span className={styles.separator} />
          </div>
          <div className={styles.socialButtons}>
            <SocialMediaButton>
              <Image src="/images/apple-icon.svg" alt="Apple" width={24} height={24} />
            </SocialMediaButton>
            <SocialMediaButton>
              <Image src="/images/google-icon.svg" alt="Google" width={24} height={24} />
            </SocialMediaButton>
          </div>
        </AuthCard>
      </div>
      <div style={{ top: '25%', right: 0 }} className={styles.effect}></div>
    </>
  );
}

export default Login;
