'use client';
import React from 'react';
import styles from './Button.module.scss';
import { AuthButtonProps, SocialMediaButtonProps } from './Button.types';
import Link from 'next/link';

export const AuthButton: React.FC<AuthButtonProps> = (props) => {
  return (
    <button className={styles.authButton} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({ children }) => {
  return <button className={styles.socialMediaButton}>{children}</button>;
};
