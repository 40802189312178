'use client';

import React from 'react';
import styles from './Card.module.scss';
import { ICardProps } from './Card.types';
import { useRouter } from 'next/navigation';

export const AuthCard: React.FC<ICardProps> = (props) => {
  const { children } = props;

  return (
    <div className={styles.card}>
      <div className={styles.cardBox}>{children}</div>
    </div>
  );
};

export const BackHomePage: React.FC = () => {
  const router = useRouter();

  return (
    <div className={styles.backToHome}>
      <button onClick={() => router.back()}>
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1932 7.78756C19.1932 8.08593 19.0747 8.37208 18.8637 8.58306C18.6527 8.79403 18.3666 8.91256 18.0682 8.91256L4.88769 8.91256L9.81819 13.3241C10.0386 13.5235 10.1711 13.8021 10.1868 14.0989C10.2024 14.3957 10.1 14.6867 9.90183 14.9082C9.70367 15.1298 9.42589 15.2639 9.12916 15.2813C8.83244 15.2987 8.54089 15.1979 8.31819 15.0011L1.19319 8.62606C1.07498 8.52053 0.980389 8.39121 0.915625 8.24659C0.850861 8.10196 0.817383 7.94528 0.817383 7.78681C0.817383 7.62834 0.850861 7.47166 0.915625 7.32703C0.980389 7.18241 1.07498 7.05309 1.19319 6.94756L8.31819 0.572559C8.42814 0.47306 8.55668 0.396277 8.69641 0.346629C8.83614 0.296981 8.9843 0.275448 9.13238 0.283266C9.28046 0.291084 9.42554 0.328101 9.55926 0.392187C9.69299 0.456272 9.81272 0.546163 9.91158 0.656689C10.0104 0.767214 10.0865 0.896194 10.1353 1.03621C10.1841 1.17622 10.2048 1.32451 10.1961 1.47254C10.1875 1.62058 10.1496 1.76544 10.0847 1.89878C10.0199 2.03213 9.92929 2.15134 9.81819 2.24956L4.88769 6.66256L18.0682 6.66256C18.3666 6.66256 18.6527 6.78109 18.8637 6.99207C19.0747 7.20304 19.1932 7.48919 19.1932 7.78756Z"
            fill="currentColor"
          />
        </svg>
        <span>Geri Dön</span>
      </button>
    </div>
  );
};
